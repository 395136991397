import React from 'react';
import Tipos from './inc/Tipos';


export default function Servicios() {
    return (
        <div>
            <Tipos />
        </div>
    )
}
