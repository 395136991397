import React from 'react';
import Lssbb from '../../assets/images/LssBB.jpg'
import SmallTrainingCards from '../inc/SmallTrainingCards';
import ReactGA from 'react-ga'

ReactGA.pageview(window.location.pathname + window.location.search);

export default function CertifLssbb() {
    return (
        <div className="container">
            <div className='newpage'>
                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <div className="col-md-6 text-left">
                                    <img src={Lssbb} className="w-50 border-bottom" alt="Formación Lean Six Sigma Black Belt" />
                                </div>
                                <h3 className="main-heading">
                                    Certificacion Lean Six Sigma Black Belt 
                                </h3>
                                <div className="underline mx-auto"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Objetivos
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                            <p>
                                El objetivo es dar a los participantes las herramientas necesarias para desarrollar el rol de un Lean Six Sigma Black Belt y poder:
                            </p>
                                <ul>
                                    <li>Comprender Lean Six Sigma y la metodología DMAIC.</li>
                                    <li>Aprender y aplicar habilidades en gestión de proyectos, gestión de procesos, lean, gestión del cambio y análisis estadístico básico.</li>
                                    <li>Comprender la función del Black Belt en una implementación exitosa de Lean Six Sigma</li>
                                    <li>Establecer, enfocar y liderar adecuadamente proyectos Lean Six Sigma.</li>
                                    <li>Saber avanzar con el proyecto de fase a fase de la metodología.</li>
                                    <li>Al finalizar, cerrar proyecto y contabilizar beneficios alcanzados.</li>
                                    <li>Presentar proyectos a compañeros, directivos y a dirección.</li>
                                    <li>Aplicar los métodos aprendidos en el proceso DMAIC al negocio de las empresas.</li>                           
                                    <li>Aplicar análisis estadístico para determinar la relación entre las entradas clave y las salidas del proceso.</li>
                                    <li>Implementar controles para mantener las ganancias.</li>
                                    <li>Gestionar eficazmente la dinámica del equipo de proyecto.</li>
                                    <li>Navegar por software de análisis estadístico (Minitab).</li>
                                    <li>Conseguir la certificación como Lean Six Sigma Black Belt basada en las normas ISO 13053 y 18404, y la CSSC, que permita participar en equipos de trabajo para la mejora de procesos y la excelencia operacional.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Dirigido a
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                   Directores, mandos intermedios, supervisores, personal técnico y cualquier profesional que tenga un conocimiento previo nivel Lean Six Sigma Green Belt. 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Lugar de realización
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Modalidad online en directo vía Zoom. Para presenciales o in company contáctanos.  
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Duración
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    40h + Examen (6 meses máximo)  
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Metodología
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Presamos la calidad de nuestra formación, por lo que ofrecemos: 
                                </p>
                                <ul>
                                    <li>Consolidar los conceptos a través de la aplicación práctica a situaciones reales.</li>
                                    <li>Materiales para las actividades.</li>
                                    <li>Enfoque práctico que permita entender los conceptos teóricos.</li>
                                    <li>La formación es también un espacio para hacer preguntas a los expertos sobre herramientas y técnicas, ejercicios, trabajo de proyectos o soporte técnico general.</li>
                                    <li>2h de Asesoramiento post-formación</li>
                                    <li>Examen oficial</li>                           
                               </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Apoyo pedagógico
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Lean Six Sigma Sis quiere que su experiencia de aprendizaje sea la más sostenida y comprometida posible. Estamos aquí para ayudarte a tener éxito. 
                                </p>
                                <p>
                                    Durante la capacitación, los estudiantes pueden enviar preguntas a los instructores. Las respuestas a las preguntas de los estudiantes se proporcionan en 3 días hábiles o menos. Si las preguntas y/o respuestas son complejas, un instructor de Lean Six Sigma Sis programará una reunión telefónica individual con el estudiante para revisar la pregunta/respuesta.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Requisitos previos
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Dado que nuestra formación sigue un enfoque progresivo, para inscribirte directamente en el nivel Black Belt, debes: 
                                </p>
                                <ul>
                                    <li>Estar certificado como Lean Six Sigma Green Belt por una entidad acreditada internacionalmente (ILSSL, CSSC, IASSC, ASQ).</li>
                                    <li>Realizar el examen de prueba de nivel de Green Belt. Contáctanos para obtener más información.</li>                        
                               </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Requisitos especiales
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Grupo mínimo necesario de 5 estudiantes.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Obtención del Certificado
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Lean Six Sigma Sis cuenta con la acreditación del CSSC (Council for Six Sigma Certification), y nuestros programas están diseñados en consonancia con el temario (body of knowledge) de las certificaciones de la IASSC, ASQ y cumplen con los estándares de la norma ISO 13053.
                                </p>
                                <p>
                                    Para obtener nuestro certificado, es fundamental aprobar el curso con una calificación igual o superior al 80%.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Insignia digital
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    Una insignia digital es una representación gráfica verificada de tu certificado. Está integrado con datos que proporcionan contexto, significado y detalles de la certificación que obtuviste.  
                                </p>
                                <p>
                                    La credencial digital se le proporciona sin costo alguno para usted. Los beneficios de solicitar una insignia digital incluyen:
                                </p>
                                <ul>
                                    <li>Compartir fácilmente la credencial digital en redes sociales y profesionales, vinculada desde cualquier correo electrónico o documento, agregada a una firma de correo electrónico o incrustada en un blog o sitio web.</li>
                                    <li>Tu certificación podrá ser verificada online en tiempo real con solo un clic.</li>
                                    <li>Acceder a información sobre el mercado laboral que relaciona tus habilidades con los empleos.</li>                        
                               </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Contenido
                                </h3>
                                <div className="underline mx-left"></div>
                            </div>
                            <div className="col-md-12 left">
                                <p>
                                    <ol>
                                        <li>
                                            Introducción Lean Six Sigma
                                            <ul>
                                                <li>
                                                    Introducción
                                                </li>
                                                <li>
                                                    Diferencia entre Lean y Six Sigma
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Estrategia y despliegue
                                            <ul>
                                                <li>
                                                    Estrategia Hoshin Kanri
                                                </li>
                                                <li>
                                                    Alineamiento estratégico
                                                </li>
                                                <li>
                                                    Selección de proyectos Lean Six Sigma 
                                                </li>
                                                <li>
                                                    Despliegue Lean Six Sigma
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            La mejora continua
                                            <ul>
                                                <li>
                                                    Lean y sus distintos tipos
                                                </li>
                                                <li>
                                                    Lean Thinking
                                                </li>
                                                <li>
                                                    Scrum y Agile 
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Gestión de proyectos Lean Six Sigma
                                            <ul>
                                                <li>
                                                    Gestión efectiva de equipos
                                                </li>
                                                <li>
                                                    Liderazgo transformacional
                                                </li>
                                                <li>
                                                    Matrix RACI 
                                                </li>
                                                <li>
                                                    Metodología DMAIC 
                                                </li>
                                                <li>
                                                    Metodología DFSS 
                                                </li>
                                                <li>
                                                    Gestión de tareas en Kanban
                                                </li>
                                                <li>
                                                    Revisión de proyecto 
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Fase Definir
                                            <ul>
                                                <li>
                                                    VSM (Mapa de Cadena de Valor)
                                                </li>
                                                <li>
                                                    VOC (Voz del Cliente)
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Fase Medir
                                            <ul>
                                                <li>
                                                    Análisis del Sistema de Medida
                                                </li>
                                                <li>
                                                    Capacidad con Datos no normales
                                                </li>
                                            </ul>
                                        </li                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                        <li>
                                            Fase Analizar
                                            <ul>
                                                <li>
                                                    SMED
                                                </li>
                                                <li>
                                                    OEE
                                                </li>
                                                <li>
                                                    TPM
                                                </li>
                                                <li>
                                                    Sistema Pull y Push
                                                </li>
                                                <li>
                                                    Análisis de regresión multilínear
                                                </li>
                                                <li>
                                                    Estadística inferencial
                                                </li>
                                                <li>
                                                    Análisis de Varianza ANOVA
                                                </li>
                                                <li>
                                                    Pruebas de hipótesis con datos normales
                                                </li>
                                                <li>
                                                    Pruebas de hipótesis con datos no normales
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Fase Mejorar
                                            <ul>
                                                <li>
                                                    Diseño de experimentos (DOE)
                                                </li>
                                                <li>
                                                    Diseño de experimentos (DOE) a factorial completo
                                                </li>
                                                <li>
                                                    Diseño de experimentos (DOE) a factorial fraccionario
                                                </li>
                                                <li>
                                                    Técnica de Lluvia de ideas
                                                </li>
                                                <li>
                                                    Técnica de los 6 sombreros
                                                </li>
                                                <li>
                                                    TRIZ
                                                </li>
                                                <li>
                                                    Documentar / diseñar soluciones
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Impacto financiero de las soluciones
                                            <ul>
                                                <li>
                                                    ROI
                                                </li>
                                                <li>
                                                    Cash Flow                                                
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Fase Controlar
                                            <ul>
                                                <li>
                                                    Cartas de Control - datos discretos
                                                </li>
                                                <li>
                                                    KPIs y Dashboards
                                                </li>
                                                <li>
                                                    Jidoka
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Gestión del cambio
                                            <ul>
                                                <li>
                                                    Monitorización de proyectos
                                                </li>
                                                <li>
                                                    ROI del programa Lean Six sigma
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Proceso de certificación Lean Six Sigma Black Belt
                                        </li>
                                    </ol> 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-c-light border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h3 className="main-heading">
                                    Otras certificaciones 
                                </h3>
                                <div className="underline mx-left"></div>
                                <SmallTrainingCards/>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}
